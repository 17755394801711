<template>
    <div>
        <v-container>
            <v-layout row wrap justify-space-around>
                <v-flex xs12 xl6 lg6>
                    <v-text-field class="my-3" label="Todo Title" rounded v-model="todo.title" outlined></v-text-field>

                </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
                <v-flex xs12 xl6 lg6>
                    <v-textarea v-model="todo.description" rounded outlined label="Todo Description"></v-textarea>
                </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
                <v-flex xs12 xl6 lg6>
                    <v-subheader>Todo Color</v-subheader>
                    <v-btn @click="setTodoColor(item)" v-for="(item, index) in color_list" :key="index" :class="item"
                        class="mx-1" x-large icon>
                        <v-icon color="white">fas fa-circle</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around mt-4>
                <v-flex xs12 xl6 lg6>
                    <v-text-field class="my-5" label="Todo Date" rounded type="date" outlined v-model="todo.date">
                    </v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
                <v-flex xs12 xl6 lg6>
                    <v-btn color="primary" x-large block rounded>Save Todo</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                todo: {},
                color_list: [
                    'green',
                    'blue',
                    'red',
                    'pink',
                    'yellow',
                    'black'
                ]
            }
        },
        computed: {
            todo_list() {
                return this.$store.getters.todo_list
            }
        },
        methods: {
            setTodoColor(color) {
                this.todo.color = color
            },
            updateTodo(){
                let index = this.$route.params.index
                this.$store.commit('updateTodo' , index , this.todo)
            }
        },
        mounted() {
            let index = this.$route.params.index
            this.todo = this.todo_list[index]
        },
    }
</script>